.text-responsive {
  font-size: min(max(0.7rem, 2.5vw), 16px);
}

.text-responsive-sm {
  font-size: min(max(0.3rem, 1.5vw), 14px);
}

.text-responsive-xs {
  font-size: min(max(0.3rem, 0.9vw), 12px);
}

.text-responsive-lg {
  font-size: min(max(0.8rem, 2vw), 16px);
}

.padding-responsive-wide {
  padding-left: min(max(0.6rem, 3vw), 22px);
  padding-right: min(max(0.6rem, 3vw), 22px);
  padding-top: min(max(0.6rem, 3vw), 8px);
  padding-bottom: min(max(0.6rem, 3vw), 8px);
}

.padding-responsive-medium {
  padding-left: min(max(0.1rem, 2vw), 14px);
  padding-right: min(max(0.1rem, 2vw), 14px);
  padding-top: min(max(0.1rem, 1vw), 8px);
  padding-bottom: min(max(0.1rem, 1vw), 8px);
}

.padding-responsive {
  padding-left: min(max(0.1rem, 2vw), 8px);
  padding-right: min(max(0.1rem, 2vw), 8px);
  padding-top: min(max(0.1rem, 2vw), 4px);
  padding-bottom: min(max(0.1rem, 2vw), 4px);
}

.color-0 {
    background-color: #77ff88;
}
.color-1 {
    background-color: #99dd88;
}
.color-2 {
    background-color: #aacc88;
}
.color-3 {
    background-color: #ccbb88;
}
.color-4 {
    background-color: #dd9988;
}
.color-5 {
    background-color: #ff7788;
}

.thin-col {
  width: 3%;
}